import { call, all, put, takeLatest } from 'redux-saga/effects';
import { Types, fulfill, failed, request, success } from '../ducks';
import { getProjects, getProject, getAssociatis } from '../../../../services';

function* loadAssociatis() {
  yield put(request());
  try {
    const { data } = yield call(getAssociatis);
    if (data) {
      yield put(success(data));
    }
  } catch (error) {
    yield put(failed(error));
  } finally {
    yield put(fulfill());
  }
}

function* loadProjects() {
  yield put(request());
  try {
    const { data } = yield call(getProjects);
    if (data) {
      yield put(success(data));
    }
  } catch (error) {
    yield put(failed(error));
  } finally {
    yield put(fulfill());
  }
}

function* loadProject(action) {
  yield put(request());
  try {
    const data = yield call(getProject, action.payload);
    if (data) {
      yield put(success(data));
    }
  } catch (error) {
    yield put(failed(error));
  } finally {
    yield put(fulfill());
  }
}

export default function* projectsSaga() {
  yield all([
    takeLatest(Types.TRIGGER, loadProjects),
    takeLatest(Types.TRIGGER_PROJECT, loadProject),
    takeLatest(Types.TRIGGER_ASSOCIATIS, loadAssociatis),
  ]);
}
