import React, { useState, useEffect, useRef } from "react";
import { Container, Row } from "react-bootstrap";
import Menu from "../../components/Menu/index";
import { trigger } from "../../storage/redux/about";
import { trigger as triggerBios } from "../../storage/redux/bio";

import { useSelector, useDispatch } from "react-redux";
import HtmlRender from "../../components/HtmlRender";
import picasso from "./../../assets/img/picasso.jpeg";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Fade as TabFade } from "./styles";
import { Fade } from "@material-ui/core";
// TAB COMPONENTS
import MobileTabMenu from "./../../components/Tabs/mobileTabFilter";
import Tabs from "../../components/Tabs/tabs";
import Contatti from "../../components/Tabs/contatti";
import Collaboratori from "../../components/Tabs/collaboratori";
import Biografia from "../../components/Tabs/biografia";
import Timeline from "../../components/Tabs/timeline";
function About(props) {
  // STATE HOOKS
  const [activeTab, setActiveTab] = useState("Contatti");
  const [showMenu, setShowMenu] = useState(false);
  const [pageLoadStatus, setPageLoadStatus] = useState(false); // rename this
  const [fade, toggleFade] = useState(false); // this is for mobile only?
  // REDUX HOOKS
  const dispatch = useDispatch();
  const { about, bios } = useSelector((state) => state);

  // MUI HOOKS
  const mobileScreens = useMediaQuery("(max-width: 768px)");

  const myRef = useRef(<div />);
  const collabsOne = about.collabs.slice(0, 6);
  const collabsTwo = about.collabs.slice(6);

  const handleScroll = () => {
    if (window.scrollY >= 37) {
      toggleFade(true);
    } else {
      toggleFade(false);
    }
  };
  const tabs = [
    "Contatti",
    "Collaboratori",
    "Biografia",
    "Formazione scolastica",
    "Esperienze professionali",
    "Attività professionali",
    "Giuria concorsi",
    "Coordinatore concorsi",
    "Studi di fattibilità",
    "Concorsi",
    "Realizzazioni",
    "Consulenze",
  ];
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [toggleFade]);
  useEffect(() => {
    dispatch(trigger());
    dispatch(triggerBios());
  }, [dispatch]);

  useEffect(() => {
    setPageLoadStatus(true);
    window.scrollTo(0, 0);

    //fade logo
    let el = document.querySelector(".fadeLogo");
    el.classList.add("fadeIn");
  }, []);
  function scrollToElement() {
    if (window.innerWidth > 750) {
      const elementToScrollTo = document.getElementById("tabs-wrapper");
      const scrollValue = elementToScrollTo.offsetHeight - 65;
      window.scrollTo({
        top: scrollValue,
        behavior: "smooth",
      });
    }
  }
  const getBiosTimeLine = (field) => {
    var data = bios.bios.find((bio) => bio.Bio_title === field);
    if (data) {
      var timeLine = [];
      data.title.map((title) => {
        timeLine.push({
          year: title.dates_description.dates,
          text: title.dates_description.description,
        });
      });
      return timeLine;
    }
    return [];
  };

  const displayTab = () => {
    return (
      <div style={{ position: "relative" }}>
        <TabFade in={activeTab === "Contatti"}>
          <div>
            <Contatti about={about} />
          </div>
        </TabFade>
        <TabFade in={activeTab === "Collaboratori"}>
          <div>
            {" "}
            <Collaboratori about={about} />
          </div>
        </TabFade>
        {bios?.bioAndDescriptions && (
          <TabFade in={activeTab === "Biografia"}>
            <div>
              <Biografia
                bioAndDescriptions={bios?.bioAndDescriptions[0]}
                about={about}
                mobileScreens={mobileScreens}
                pageLoadStatus={pageLoadStatus}
                myRef={myRef}
                fade={fade}
              />
            </div>
          </TabFade>
        )}

        <TabFade in={activeTab === "Formazione scolastica"}>
          <div>
            {" "}
            <Timeline timeline={getBiosTimeLine(activeTab)} />
          </div>
        </TabFade>
        <TabFade in={activeTab === "Esperienze professionali"}>
          <div>
            {" "}
            <Timeline timeline={getBiosTimeLine(activeTab)} />
          </div>
        </TabFade>
        <TabFade in={activeTab === "Attività professionali"}>
          <div>
            {" "}
            <Timeline timeline={getBiosTimeLine(activeTab)} />
          </div>
        </TabFade>
        <TabFade in={activeTab === "Giuria concorsi"}>
          <div>
            {" "}
            <Timeline timeline={getBiosTimeLine(activeTab)} />
          </div>
        </TabFade>
        <TabFade in={activeTab === "Coordinatore concorsi"}>
          <div>
            {" "}
            <Timeline timeline={getBiosTimeLine(activeTab)} />
          </div>
        </TabFade>
        <TabFade in={activeTab === "Studi di fattibilità"}>
          <div>
            {" "}
            <Timeline timeline={getBiosTimeLine(activeTab)} />
          </div>
        </TabFade>
        <TabFade in={activeTab === "Concorsi"}>
          <div>
            {" "}
            <Timeline timeline={getBiosTimeLine(activeTab)} />
          </div>
        </TabFade>
        <TabFade in={activeTab === "Realizzazioni"}>
          <div>
            {" "}
            <Timeline timeline={getBiosTimeLine(activeTab)} />
          </div>
        </TabFade>
        <TabFade in={activeTab === "Consulenze"}>
          <div>
            {" "}
            <Timeline timeline={getBiosTimeLine(activeTab)} />
          </div>
        </TabFade>
      </div>
    );
  };
  const handleBackground = () => {
    var tab = document.getElementById("tab-element");
    var button = document.getElementById("tab-icon-button");
    tab.classList.remove("bg-default");
    button.classList.remove("bg-default");

    setTimeout(() => {
      tab.classList.add("bg-default");
      button.classList.add("bg-default");
    }, 550);
    setTimeout(() => {
      var bgAbout = document.getElementById("bg-about");
      console.log("about ", bgAbout);
      if (bgAbout) bgAbout.classList.add("bg-default");
    }, 700);
  };
  return (
    <>
      <Menu
        goBack={() => {
          if (showMenu) {
            setShowMenu(false);
          } else {
            props.history.goBack();
          }
        }}
        page="about"
        hideNavbar={showMenu}
        startUpAnimation={true}
      />
      {showMenu && (
        <MobileTabMenu
          tabs={tabs}
          active={showMenu}
          activeTab={activeTab}
          setActiveTab={(e) => setActiveTab(e)}
          close={() => {
            handleBackground();
            setShowMenu(false);
            setTimeout(() => {
              scrollToElement();
            }, 200);
          }}
        />
      )}
      <div
        style={{ display: showMenu ? "none" : "block" }}
        className="aboutPage"
      >
        <Container
          id={"tabs-wrapper"}
          ref={myRef}
          fluid
          className="w-md-100 pb-4 test add-padding-mobile"
          style={{ overflowX: "hidden" }}
        >
          <div className="hide-mobile-image ">
            {about.contact !== undefined ? (
              <>
                {about.contact.image === null ||
                about.contact.image === undefined ? null : mobileScreens ? (
                  // Fade animation on scroll strictly for mobiles
                  <Fade in={!fade} timeout={1000}>
                    <img
                      className="col-mattia about-image"
                      src={about.contact.image.url}
                    />
                  </Fade>
                ) : (
                  // Fade animation on page load strictly for desktop
                  <Fade in={pageLoadStatus} timeout={1000}>
                    <img
                      className="col-mattia about-image"
                      src={about.contact.image.url}
                    />
                  </Fade>
                )}
              </>
            ) : null}
          </div>
        </Container>
        <div className="about-text-columns-wrapper about-wrapper-z-index">
          <Container ref={myRef} fluid className="w-md-100 pb-4">
            <Row>
              <div className="hack col-mattia"> </div>
              <div
                id="tab-element"
                className="col-about-tab pt-20 about-sticky-menu-header"
              >
                <Tabs
                  tabs={tabs}
                  scrollToElement={scrollToElement}
                  setActiveTab={(e) => setActiveTab(e)}
                  menuOpen={showMenu}
                  setMenuOpen={(e) => setShowMenu(e)}
                  activeTab={activeTab}
                />
              </div>
              <div className="col-mattia-hack "> </div>
              <div
                style={{ position: "relative" }}
                className="col-about-tab-content pt-20 noPadding about-content-z-index"
              >
                {displayTab()}
              </div>
            </Row>
          </Container>
          <div style={{ paddingBottom: "100px" }}></div>
        </div>
      </div>
    </>
  );
}

export default About;
