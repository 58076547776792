import React, { useEffect, useRef, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { Text } from "./styles";

export default function Collaboratori({ about, setActiveTab, isTabClosing, ref }) {
  var names = about.collabs.map((collab) => {
    return collab.name;
  });

  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    setOpacity(1);
  },[])

  const [animatedBorderContainerHeight, setAnimatedBorderContainerHeight] = useState(0);
  const animatedContainerRef = useRef(null);

  useEffect(() => {
    if (animatedContainerRef.current) {
      setAnimatedBorderContainerHeight(100);
    }
    if (isTabClosing) {
      setAnimatedBorderContainerHeight(0);
    }
  }, [animatedContainerRef.current, isTabClosing]);

  return (
    <Container fluid className="w-md-100 associati-container" ref={ref}>
      {/* <div className="animated-border-container" ref={animatedContainerRef} style={{height: `${animatedBorderContainerHeight}%`}} /> */}
      <Row className="associati-row" style={{opacity: opacity}}>
      <Text className="mobile-tab-title associati-title" onClick={() => setActiveTab(undefined)}>Collaboratori</Text>
        <div className="hack col-mattia"> </div>
        <div className="col-about-tab-mobile">
          {names.map((name) => {
            if(name !== " "){
              return (
                <Text style={{lineHeight:"25px"}} key={"name " + name}>{name}</Text>
              )
            }
          })}
        </div>
      </Row>
    </Container>
  );
}
