import axios from "axios";
import { parseProject } from "./parser";

export const getTags = async () => {
  try {
    const { data } = await axios.post(`${"https://pc.stopplay.app"}/graphql`, {
      query: `
      query {
        tags {
          name
          id
        }
      }
      `,
    });
    return data;
  } catch (error) {
    return;
  }
};

export const getAssociatis = async () => {
  try {
    const { data } = await axios.post(`${"https://pc.stopplay.app"}/graphql`, {
      query: `
      query {
        associatis {
          Associati {
            Title
            Description
            Curriculum_Vitae_PDF {
              url
            }
            PhoneNumber
            PhoneNumber_URL
            Email_Address
            Email_Address_URL
          }
        }
      }
      `,
    });
    return data;
  } catch (error) {
    return;
  }
};

export const getProjects = async () => {
  try {
    const { data } = await axios.post(`${"https://pc.stopplay.app"}/graphql`, {
      query: `
      query {
        projects {
          name
          codename
          tags {
            id
          }
          title
          show_title
          main_image {
            url
          }
          gallery {
            label
            label_position
            orientation
            image {
              url
            }
          }
          left_description
          right_description
          position_id
        }
      }
      `,
    });
    return data;
  } catch (error) {
    return;
  }
};

export const getProject = async (codename) => {
  try {
    const { data } = await axios.post(`${"https://pc.stopplay.app"}/graphql`, {
      query: `
      query {
        projects(where: {
          codename: "${codename}"
        }) {
          name
          codename
          title
          show_title
          main_image {
            url
          }
          gallery {
            label
            label_position
            orientation
            image {
              url
            }
          }
          left_description
          right_description
          position_id
        }
      }
      `,
    });
    return parseProject(data);
  } catch (error) {
    return;
  }
};

export const getAbout = async () => {
  try {
    const { data } = await axios.post(`${"https://pc.stopplay.app"}/graphql`, {
      query: `
      query {
        about {
          left_description
          right_description
          contact {
            name
            email
            address
            address_complement
            phone
            Region
            image {
              url
            }
          }
          collabs {
            name
            id
          }
        }
      }      
      `,
    });
    return data;
  } catch (error) {
    return;
  }
};

export const getBios = async () => {
  try {
    const { data } = await axios.post(`${"https://pc.stopplay.app"}/graphql`, {
      query: `
      query {
        bios {
          id
          Bio_title
          title {
            dates_description {
              dates
              description
            }
          }
        }
        bioAndDescriptions {
          id
          Description
          Title
        }
      }      
      `,
    });
    return data;
  } catch (error) {
    return;
  }
};
