// ACTION TYPES
export const Types = {
  TRIGGER: 'ASSOCIATIS/TRIGGER',
  REQUEST: 'ASSOCIATIS/REQUEST',
  ERROR: 'ASSOCIATIS/ERROR',
  SUCCESS: 'ASSOCIATIS/SUCCESS',
  FULFILL: 'ASSOCIATIS/FULFILL',
};

// INITIAL STATE
const initialState = {
  loading: false,
  error: '',
  associatis: [],
};

// REDUCER
export default (state = initialState, action) => {
  switch (action.type) {
    case Types.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Types.ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case Types.SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case Types.FULFILL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

// ACTIONS
export const trigger = () => {
  return {
    type: Types.TRIGGER,
  };
};

export const request = () => {
  return {
    type: Types.REQUEST,
  };
};

export const fulfill = () => {
  return {
    type: Types.FULFILL,
  };
};

export const failed = (error) => {
  return {
    type: Types.ERROR,
    payload: error,
  };
};

export const success = (payload) => {
  return {
    type: Types.SUCCESS,
    payload,
  };
};
