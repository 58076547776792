import React, { useEffect, useRef, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { Text } from "./styles";
import arrow from "./../../assets/img/arrow-down.svg";
import external from "./../../assets/img/external.svg";
import { useSelector } from "react-redux";

export default function Associati({
  noAssociati,
  setNoAssociati,
  setActiveTab,
  isTabClosing,
  ref,
}) {
  const [currentAssociati, setCurrentAssociati] = React.useState();
  var { associatis, loading } = useSelector((state) => state.associatis);
  const [opacityAssociatisList, setOpacityAssociatisList] = useState(0);
  const [opacityCurrentAssociati, setOpacityCurrentAssociati] = useState(0);
  const refAssociatis = useRef();
  const refBio = useRef();

  useEffect(() => {
    setOpacityAssociatisList(1);
  }, []);

  useEffect(() => {
    if (noAssociati) {
      setCurrentAssociati();
      setOpacityAssociatisList(1);
    } else {
      setOpacityAssociatisList(0);
    }
  }, [noAssociati]);

  const [animatedBorderContainerHeight, setAnimatedBorderContainerHeight] =
    useState(0);
  const animatedContainerRef = useRef(null);

  useEffect(() => {
    if (animatedContainerRef.current) {
      if (noAssociati) {
        setAnimatedBorderContainerHeight("100%");
      } else {
        const elementHeight = refBio.current?.clientHeight || 0;
        setAnimatedBorderContainerHeight(elementHeight + "px");
      }
      if (isTabClosing) {
        setAnimatedBorderContainerHeight("0%");
      }
    }
  }, [animatedContainerRef.current, noAssociati, isTabClosing]);

  useEffect(() => {
    if (currentAssociati) {
      const el = document.getElementById("associati-tabfade");
      if (el) {
        el.scrollTo({ top: 0, behavior: "smooth" });
      }
    }
  }, [currentAssociati]);

  return (
    <Container
      fluid
      className="w-md-100 associati-container"
      id="associati-container"
      ref={ref}
    >
      <div className="associati-container-border" />
      {/* <div
        className="animated-border-container"
        ref={animatedContainerRef}
        style={{ height: `${animatedBorderContainerHeight}` }}
      /> */}
      <Row className="associati-row">
        <Text
          className="mobile-tab-title associati-title"
          onClick={() => setActiveTab(undefined)}
          style={{ visibility: !noAssociati ? "hidden" : "visible" }}
        >
          Associati
        </Text>
        <div className="hack col-mattia"> </div>

        <div
          className="associati-names-container"
          style={{ opacity: opacityAssociatisList }}
        >
          {associatis[0].Associati?.map((associati, index) => (
            <>
              <Text
                style={{ lineHeight: "25px", cursor: "pointer" }}
                key={"name " + index}
                className="associati-name bold-hover"
                onClick={() => {
                  setCurrentAssociati(associati);
                  setNoAssociati(false);
                }}
              >
                {associati.Title}
                <img className="show-mobile" src={arrow} alt="arrow" />
              </Text>
            </>
          ))}
        </div>

        <div
          className="associati-bio-text"
          id="associati-bio-text"
          ref={refBio}
          style={{ visibility: noAssociati ? "hidden" : "visible" }}
        >
          <Text
            className="associati-bio-title"
            onClick={() => setNoAssociati(true)}
          >
            {currentAssociati?.Title}
            <img className="show-mobile" src={arrow} alt="arrow" />
          </Text>
          <Text
            style={{ lineHeight: "22px" }}
            dangerouslySetInnerHTML={{ __html: currentAssociati?.Description }}
          ></Text>
          {currentAssociati?.Curriculum_Vitae_PDF.length > 0 && (
            <Text className="associati-name associati-contact">
              <a
                target="_blank"
                href={currentAssociati?.Curriculum_Vitae_PDF[0]?.url}
                style={{ textDecoration: "none" }}
              >
                Curriculum Vitae PDF
              </a>
              <img className="show-mobile" src={external} alt="external url" />
            </Text>
          )}
          {currentAssociati?.Email_Address && (
            <Text className="associati-name associati-contact">
              {currentAssociati?.Email_Address && (
                <a
                  target="_blank"
                  href={currentAssociati?.Email_Address_URL}
                  style={{ textDecoration: "none" }}
                >
                  {currentAssociati?.Email_Address}
                </a>
              )}
              <img className="show-mobile" src={external} alt="external url" />
            </Text>
          )}
          {currentAssociati?.PhoneNumber && (
            <Text className="associati-name associati-contact">
              {currentAssociati?.PhoneNumber && (
                <a
                  target="_blank"
                  href={currentAssociati?.PhoneNumber_URL}
                  style={{ textDecoration: "none" }}
                >
                  {currentAssociati?.PhoneNumber}
                </a>
              )}
              <img className="show-mobile" src={external} alt="external url" />
            </Text>
          )}
          <div style={{ marginTop: "57px" }} />
          {associatis[0].Associati?.map((associati, index) => (
            <d>
              {associati.Title === currentAssociati?.Title ? (
                ""
              ) : (
                <Text
                  style={{ lineHeight: "25px", cursor: "pointer" }}
                  key={"name " + index}
                  className="associati-name bold-hover"
                  onClick={() => {
                    setCurrentAssociati(associati);
                  }}
                >
                  {associati.Title}
                </Text>
              )}
            </d>
          ))}
          <div style={{ marginTop: "57px" }} />
          <div className="associati-bio-bottom" />
        </div>
        {/* <div className="associati-bio-text" ref={refBio}>
            <Text
              className="associati-bio-title"
              onClick={() => setNoAssociati(true)}
            >
              {currentAssociati?.Title}
              <img className="show-mobile" src={arrow} alt="arrow" />
            </Text>
            <Text
              style={{ lineHeight: "22px" }}
              dangerouslySetInnerHTML={{ __html: currentAssociati?.Description }}
            ></Text>
            {currentAssociati?.Curriculum_Vitae_PDF.length > 0 && (
              <Text className="associati-name associati-contact">
                <a
                  target="_blank"
                  href={currentAssociati?.Curriculum_Vitae_PDF[0]?.url}
                >
                  Curriculum Vitae PDF
                </a>
                <img
                  className="show-mobile"
                  src={external}
                  alt="external url"
                />
              </Text>
            )}
            {currentAssociati?.Email_Address && (
              <Text className="associati-name associati-contact">
                {currentAssociati?.Email_Address && (
                  <a target="_blank" href={currentAssociati?.Email_Address_URL}>
                    {currentAssociati?.Email_Address}
                  </a>
                )}
                <img
                  className="show-mobile"
                  src={external}
                  alt="external url"
                />
              </Text>
            )}
            {currentAssociati?.PhoneNumber && (
              <Text className="associati-name associati-contact">
                {currentAssociati?.PhoneNumber && (
                  <a target="_blank" href={currentAssociati?.PhoneNumber_URL}>
                    {currentAssociati?.PhoneNumber}
                  </a>
                )}
                <img
                  className="show-mobile"
                  src={external}
                  alt="external url"
                />
              </Text>
            )}
            <div style={{ marginTop: "57px" }} />
            <div className="associati-bio-bottom" />
          </div>  */}
      </Row>
    </Container>
  );
}
