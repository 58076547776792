import React, { useEffect, useState, useRef } from "react";
// REACT ROUTER DOM
import { Link } from "react-router-dom";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Close } from "@material-ui/icons";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { Container, Row } from "react-bootstrap";

// STYLED COMPONENTS
import {
  LogoContainer,
  Text,
  ButtonClose,
  BG,
  GalleryName,
  BGabout,
  TextLogo,
  AboutContainer,
  AboutMenu,
  LogoContainerMobile,
  GalleryNameMobile,
} from "./styles";
import { MenuContainer } from "../../pages/Home/styles";
// STYLES
import "./style.css";
import { trigger } from "../../storage/redux/about";
import { trigger as triggerBios } from "../../storage/redux/bio";
import { Fade as TabFade } from "../../pages/About/styles";
import { Fade } from "@material-ui/core";
import Tabs from "../../components/Tabs/tabs";
import Contatti from "../../components/Tabs/contatti";
import Collaboratori from "../../components/Tabs/collaboratori";
import Biografia from "../../components/Tabs/biografia";
import Timeline from "../../components/Tabs/timeline";
import Associati from "../Tabs/associati";

function Menu(props) {
  const { project } = useSelector((state) => state.projects);
  const [openFilter, setOpenFilter] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [arrowDownColor, setArrowDownColor] = useState("#848484");
  const myRef = useRef(<div />);
  const refAssociati = useRef(null);
  const refCollaboratori = useRef(null);
  const refTimeline = useRef(null);
  const [tabsBorderHeight, setTabsBorderHeight] = useState(0);

  useEffect(() => {
    if (!openFilter) {
      //Scroll to top
      window.scrollTo(0, 0); // Keep an eye on this one
      //fade logo
      let el = document.querySelector(".fadeLogo");
      el.classList.add("fadeIn");
    }
  }, [openFilter]);

  // STATE HOOKS
  const [activeTab, setActiveTab] = useState(undefined);
  const [noAssociati, setNoAssociati] = useState(true);
  const [showMenu, setShowMenu] = useState(false);
  const [pageLoadStatus, setPageLoadStatus] = useState(false); // rename this
  const [fade, toggleFade] = useState(false); // this is for mobile only?
  const [logoOpacity, setLogoOpacity] = useState(0);
  // REDUX HOOKS
  const dispatch = useDispatch();
  const { about, bios } = useSelector((state) => state);

  useEffect(() => {
    const element = document.getElementById("gallery-name-mobile");

    if (element) {
      if (props.openFilter) {
        element.classList.remove("show-after");
      } else {
        element.classList.add("show-after");
      }
    }
  }, [props.openFilter]);

  const openGalleryNameMobile = () => {
    props.onWorkPress();
    return;
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLogoOpacity(1);
    }, 800);

    if (props.sourcePage === "home") {
      const redirectTimeout = setTimeout(() => {
        window.location.href = "/work/gaggiorisanamentorustico";
      }, 2000);

      return () => {
        clearTimeout(redirectTimeout);
      };
    }

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    const el = document.getElementById("about-page");
    if (el) {
      el.scrollTop = 0;
    }
  }, [activeTab]);

  // MUI HOOKS
  const mobileScreens = useMediaQuery("(max-width: 768px)");

  const aboutRef = useRef(<div />);
  const collabsOne = about.collabs.slice(0, 6);
  const collabsTwo = about.collabs.slice(6);

  const [animatedBorderContainerHeight, setAnimatedBorderContainerHeight] =
    useState(0);
  const animatedContainerRef = useRef(null);

  useEffect(() => {
    if (isMenuOpen && !activeTab) {
      setAnimatedBorderContainerHeight("100%");
    } else {
      setAnimatedBorderContainerHeight(0);
    }
  }, [activeTab, isMenuOpen]);

  const handleScroll = () => {
    if (window.scrollY >= 37) {
      toggleFade(true);
    } else {
      toggleFade(false);
    }
  };
  const tabs = [
    "Associati",
    "Collaboratori",
    "Studi di fattibilità",
    "Concorsi",
    "Realizzazioni",
    "Consulenze",
  ];
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [toggleFade]);
  useEffect(() => {
    dispatch(trigger());
    dispatch(triggerBios());
  }, [dispatch]);

  useEffect(() => {
    setPageLoadStatus(true);
    window.scrollTo(0, 0);

    //fade logo
    let el = document.querySelector(".fadeLogo");
    el.classList.add("fadeIn");
  }, []);
  function scrollToElement() {
    if (window.innerWidth > 750) {
      const elementToScrollTo = document.getElementById("tabs-wrapper");
      const scrollValue = elementToScrollTo.offsetHeight - 65;
      window.scrollTo({
        top: scrollValue,
        behavior: "smooth",
      });
    }
  }
  const getBiosTimeLine = (field) => {
    var data = bios.bios.find((bio) => bio.Bio_title === field);
    if (data) {
      var timeLine = [];
      data.title.map((title) => {
        timeLine.push({
          year: title.dates_description.dates,
          text: title.dates_description.description,
        });
      });
      return timeLine;
    }
    return [];
  };

  const [associatiBioBorderHeight, setAssociatiBioBorderHeight] = useState(0);
  useEffect(() => {
    if(!noAssociati) {
      const containerElement = document.getElementById("associati-bio-text");
      if (containerElement) {
        const containerHeight = containerElement.clientHeight + 115;
        setAssociatiBioBorderHeight(containerHeight);
      }
    } else {
      setAssociatiBioBorderHeight(0);
    }
  }, [noAssociati]);

  const displayTab = () => {
    return (
      <div style={{ position: "relative" }}>
        <div
          className="tab-border"
          id="tab-border"
          style={{ height: `${tabsBorderHeight}px` }}
        />
        {activeTab === "Associati" && (
          <TabFade in={activeTab === "Associati"} id={"associati-tabfade"}>
            <div className="associati-bio-border" id="associati-bio-border" style={{ opacity: noAssociati ? 0 : 1, height: associatiBioBorderHeight }}  />
            <div>
              {" "}
              <Associati
                ref={refAssociati}
                noAssociati={noAssociati}
                setNoAssociati={setNoAssociati}
                about={about}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                isTabClosing={isTabClosing}
              />
            </div>
          </TabFade>
        )}
        {activeTab === "Collaboratori" && (
          <TabFade in={activeTab === "Collaboratori"}>
            <div>
              {" "}
              <Collaboratori
                ref={refCollaboratori}
                isMenuClosing={isMenuClosing}
                about={about}
                setActiveTab={setActiveTab}
                isTabClosing={isTabClosing}
              />
            </div>
          </TabFade>
        )}
        {activeTab === "Studi di fattibilità" && (
          <TabFade in={activeTab === "Studi di fattibilità"}>
            <div>
              {" "}
              <Timeline
                ref={refTimeline}
                timeline={getBiosTimeLine(activeTab)}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                isTabClosing={isTabClosing}
              />
            </div>
          </TabFade>
        )}
        {activeTab === "Concorsi" && (
          <TabFade in={activeTab === "Concorsi"}>
            <div>
              {" "}
              <Timeline
                timeline={getBiosTimeLine(activeTab)}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                isTabClosing={isTabClosing}
              />
            </div>
          </TabFade>
        )}
        {activeTab === "Realizzazioni" && (
          <TabFade in={activeTab === "Realizzazioni"}>
            <div>
              {" "}
              <Timeline
                timeline={getBiosTimeLine(activeTab)}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                isTabClosing={isTabClosing}
              />
            </div>
          </TabFade>
        )}
        {activeTab === "Consulenze" && (
          <TabFade in={activeTab === "Consulenze"}>
            <div>
              {" "}
              <Timeline
                timeline={getBiosTimeLine(activeTab)}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                isTabClosing={isTabClosing}
              />
            </div>
          </TabFade>
        )}

        {/* {bios?.bioAndDescriptions && (
          <TabFade in={activeTab === "Biografia"}>
            <div>
              <Biografia
                bioAndDescriptions={bios?.bioAndDescriptions[0]}
                about={about}
                mobileScreens={mobileScreens}
                pageLoadStatus={pageLoadStatus}
                myRef={myRef}
                fade={fade}
              />
            </div>
          </TabFade>
        )}

        <TabFade in={activeTab === "Formazione scolastica"}>
          <div>
            {" "}
            <Timeline timeline={getBiosTimeLine(activeTab)} />
          </div>
        </TabFade>
        <TabFade in={activeTab === "Esperienze professionali"}>
          <div>
            {" "}
            <Timeline timeline={getBiosTimeLine(activeTab)} />
          </div>
        </TabFade>
        <TabFade in={activeTab === "Attività professionali"}>
          <div>
            {" "}
            <Timeline timeline={getBiosTimeLine(activeTab)} />
          </div>
        </TabFade>
        <TabFade in={activeTab === "Giuria concorsi"}>
          <div>
            {" "}
            <Timeline timeline={getBiosTimeLine(activeTab)} />
          </div>
        </TabFade>
        <TabFade in={activeTab === "Coordinatore concorsi"}>
          <div>
            {" "}
            <Timeline timeline={getBiosTimeLine(activeTab)} />
          </div>
        </TabFade> */}
      </div>
    );
  };
  const [isMenuClosing, setIsMenuClosing] = useState(false);
  const [isTabClosing, setIsTabClosing] = useState(false);

  const handleMenuClose = () => {
    setIsMenuClosing(true);
    setIsMenuOpen(false);
    // setTimeout(() => {
    // }, 400);
  };

  const handleMenuOpen = (e) => {
    setIsMenuClosing(false);
    setIsMenuOpen(e);
  };

  const handleMobileMenuOpen = (e) => {
    if (!noAssociati) {
      setNoAssociati(true);
      setActiveTab(undefined);
      return;
    }
    if (activeTab) {
      setActiveTab(undefined);
      return;
    }
    setIsMenuOpen(false);
    // setIsMenuClosing(true);
    // // setIsMenuOpen(false);
    // setAnimatedBorderContainerHeight(0);
    // setTimeout(() => {
    //   if (!noAssociati) {
    //     setNoAssociati(true);
    //     setActiveTab(undefined);
    //     return;
    //   }
    // }, 600);
    // if (activeTab) {
    //   setActiveTab(undefined);
    //   return;
    // }
  };

  const handleActiveTab = (tab) => {
    setTimeout(() => {
      const containerElement =
        document.getElementsByClassName("associati-row")[0];
      if (containerElement) {
        const containerHeight = containerElement.clientHeight + 35;
        setTabsBorderHeight(containerHeight);
      }
    }, 30);
    if (activeTab) {
      setIsTabClosing(true);
      setAnimatedBorderContainerHeight(0);
      setIsTabClosing(false);
      if (tab === "Associati") {
        setNoAssociati(true);
      }
      setActiveTab(tab);
      // setTimeout(() => {
      //   setIsTabClosing(false);
      //   if (tab === "Associati") {
      //     setNoAssociati(true);
      //   }
      //   setActiveTab(tab);
      // }, activeTab === "Realizzazioni" ? 1600 : 600);
    } else {
      setIsTabClosing(false);
      if (tab === "Associati") setNoAssociati(true);
      setActiveTab(tab);
    }
  };

  const handleBackground = () => {
    var tab = document.getElementById("tab-element");
    var button = document.getElementById("tab-icon-button");
    tab.classList.remove("bg-default");
    button.classList.remove("bg-default");

    setTimeout(() => {
      tab.classList.add("bg-default");
      button.classList.add("bg-default");
    }, 550);
    setTimeout(() => {
      var bgAbout = document.getElementById("bg-about");
      console.log("about ", bgAbout);
      if (bgAbout) bgAbout.classList.add("bg-default");
    }, 700);
  };

  if (props.page === "about") {
    return (
      <>
        <div className="about-centering">
          <ButtonClose onClick={() => props.goBack()}>
            <Close
              style={{
                position: "fixed",
                color: "#5a5a5a",
                top: "20px",
                left: "26px",
                width: "18px",
                cursor: "pointer",
                zIndex: 332,
              }}
            />
          </ButtonClose>
        </div>

        <Container close className="w-md-100 fadeLogo decoration">
          {window.location.pathname !== "/about" ? (
            <Link to="/about">
              <Text black>Piero Conconi Architetto</Text>
            </Link>
          ) : !props.hideNavbar ? (
            <Text className="gray-text-mobile" black>
              Piero Conconi Architetto
            </Text>
          ) : null}
        </Container>
        {!props.hideNavbar && (
          <>
            {" "}
            <BGabout id="bg-about" />
            <BGabout id="bg-about" />
          </>
        )}
      </>
    );
  }

  const LogoText = () => (
    <>
      <div style={{ padding: "5px 17px 5px 6px" }} id="p-logo">
        <TextLogo style={{ color: props?.color || "#000" }}>P</TextLogo>
      </div>
      <div
        style={{
          borderLeft: `1px solid ${props?.color || "#000"}`,
          padding: "5px 17px 5px 6px",
        }}
      >
        <TextLogo style={{ color: props?.color || "#000" }}>C</TextLogo>
      </div>
      <div
        style={{
          borderLeft: `1px solid ${props?.color || "#000"}`,
          padding: "5px 17px 5px 6px",
        }}
      >
        <MenuContainer
          onMouseEnter={() => setArrowDownColor("#000")}
          onMouseLeave={() => setArrowDownColor("#848484")}
          onClick={() => handleMenuOpen(!isMenuOpen)}
        >
          <TextLogo style={{ color: props?.color || "#000" }}>
            Architetti
          </TextLogo>
          {props?.sourcePage !== "home" ? (
            <KeyboardArrowDownIcon
              id="arrow-down-menu"
              style={{
                color: arrowDownColor,
                paddingBottom: "2px",
                marginLeft: "6px",
              }}
            />
          ) : (
            ""
          )}
        </MenuContainer>
      </div>
    </>
  );

  const menuContent = (
    <LogoContainer
      id="logo-container"
      className="w-md-100 fadeLogo decoration"
      style={{
        transform: isMenuOpen ? "translateY(-200px)" : "none",
        transition: "transform 0.3s ease",
      }}
      onClick={() => {
        setOpenFilter(!openFilter);
      }}
    >
      <LogoText />
    </LogoContainer>
  );

  const menuContentMobile = (
    <>
      <LogoContainerMobile
        id="logo-container-mobile"
        style={{
          transform: isMenuOpen ? "translateY(-200px)" : "none",
        }}
        onClick={() => {
          setOpenFilter(!openFilter);
        }}
      >
        <LogoText />
      </LogoContainerMobile>

      {props.sourcePage !== "home" && (
        <GalleryNameMobile
          onClick={openGalleryNameMobile}
          black
          id="gallery-name-mobile"
        >
          {project.name}
        </GalleryNameMobile>
      )}
    </>
  );

  return (
    <>
      <div
        className="background-nav-menu"
        id="fade-logo-container"
        style={{ opacity: logoOpacity }}
      >
        {props?.sourcePage === "home" ? (
          <Link to="/work/gaggiorisanamentorustico">
            {menuContent}
            {menuContentMobile}
          </Link>
        ) : (
          <>
            {menuContent}
            {menuContentMobile}
            <Link
              onClick={() => {
                setOpenFilter(!openFilter);
              }}
              to="/about"
              id="openFilterEl"
              style={{ position: "absolute", top: "-100px" }}
            >
              <Text className="marginLeftMobile">Piero Conconi Architetto</Text>
            </Link>
            {isMenuOpen && (
              <AboutContainer>
                <ButtonClose
                  onClick={() => handleMenuClose()}
                  className="close-button"
                >
                  <Close
                    style={{
                      position: "fixed",
                      color: "#5a5a5a",
                      top: "20px",
                      left: "26px",
                      width: "18px",
                      cursor: "pointer",
                      zIndex: 332,
                    }}
                  />
                </ButtonClose>
                <>
                  <div
                    style={{
                      display: showMenu ? "none" : "block",
                      overflowX: "hidden",
                    }}
                    className="aboutPage"
                    id="about-page"
                  >
                    {/* <Container
                      id={"tabs-wrapper"}
                      ref={myRef}
                      fluid
                      className="w-md-100 pb-4 test add-padding-mobile"
                      style={{ overflowX: "hidden" }}
                    >
                      <div className="hide-mobile-image ">
                        {about.contact !== undefined ? (
                          <>
                            {about.contact.image === null ||
                            about.contact.image ===
                              undefined ? null : mobileScreens ? (
                              // Fade animation on scroll strictly for mobiles
                              <Fade in={!fade} timeout={1000}>
                                <img
                                  className="col-mattia about-image"
                                  src={about.contact.image.url}
                                />
                              </Fade>
                            ) : (
                              // Fade animation on page load strictly for desktop
                              <Fade in={pageLoadStatus} timeout={1000}>
                                <img
                                  className="col-mattia about-image"
                                  src={about.contact.image.url}
                                />
                              </Fade>
                            )}
                          </>
                        ) : null}
                      </div>
                    </Container> */}
                    <div className="about-text-columns-wrapper about-wrapper-z-index">
                      <Container ref={aboutRef} fluid className=" pb-4">
                        {/* <div
                          className="animated-border-container-mobile-tab"
                          ref={animatedContainerRef}
                          style={{ height: `${animatedBorderContainerHeight}` }}
                        /> */}
                        <Row>
                          <div
                            id="tab-element"
                            className="col-about-tab pt-20 about-sticky-menu-header"
                            style={{ marginLeft: "130px !important" }}
                          >
                            <Tabs
                              tabs={tabs}
                              scrollToElement={scrollToElement}
                              setActiveTab={(e) => handleActiveTab(e)}
                              openFilter={openFilter}
                              setOpenFilter={(e) => setOpenFilter(e)}
                              menuOpen={showMenu}
                              isMenuClosing={isMenuClosing}
                              activeTab={activeTab}
                            />
                          </div>

                          <div className="col-mattia-hack "> </div>
                          <div
                            style={{ position: "relative" }}
                            className="col-about-tab-content pt-20 noPadding about-content-z-index"
                          >
                            <ButtonClose id="tab-menu-close-mobile">
                              <Close
                                onClick={(e) => {
                                  handleMobileMenuOpen(e);
                                }}
                                style={{
                                  width: "18px",
                                  fill: "#848484",
                                }}
                              />
                            </ButtonClose>
                            {displayTab()}
                          </div>
                        </Row>
                      </Container>
                      <div style={{ paddingBottom: "100px" }}></div>
                    </div>
                  </div>
                </>
              </AboutContainer>
            )}
            <GalleryName
              onClick={props.onWorkPress}
              black
              className="hide-mobile"
              id="gallery-name"
              style={{
                transform: isMenuOpen
                  ? "translateY(-250%)"
                  : "translateY(-50%)",
                transition: "transform 0.3s ease",
              }}
            >
              {project.name}
              <KeyboardArrowDownIcon
                style={{ color: arrowDownColor, paddingBottom: "2px" }}
              />
            </GalleryName>
          </>
        )}
      </div>
    </>
  );
}

export default Menu;
