import { all, fork } from 'redux-saga/effects';
// Sagas
import tagsSaga from '../redux/tags/saga';
import projectsSaga from '../redux/projects/saga';
import associatisSaga from '../redux/associatis/saga';
import aboutSaga from '../redux/about/saga';
import biosSaga from '../redux/bio/saga';
export default function* rootSaga() {
  yield all([fork(associatisSaga), fork(projectsSaga), fork(aboutSaga), fork(tagsSaga), fork(biosSaga)]);
}
