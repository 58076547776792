import React, { useState, useEffect } from "react";

import { Main, TextHome, TextHomeWrapper } from "./styles";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { trigger } from "../../storage/redux/about";
import Logo1 from "../../assets/logo/logo_1.svg";
import Logo2 from "../../assets/logo/logo_2.svg";
import Menu from "../../components/Menu";

function Home() {
  const [hotSpot, setHotSpot] = useState(" ");
  const [logoOpacity, setLogoOpacity] = useState(0);
  const dispatch = useDispatch();
  const { about } = useSelector((state) => state);

  useEffect(() => {
    dispatch(trigger());
  }, [dispatch]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLogoOpacity(1);
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <Main>
      <Menu
        color="#ffffff"
        sourcePage="home"
        // onWorkPress={() => {
        //   setOpacityIMG(1);
        //   setOpenFilter(true);
        // }}
        // page={match.params.name}
        // opacity={opacityIMG}
        // renderArrow={arrow && haveNext}
        // title={name}
        // arrowLink={haveNext ? `/work/${nextLink}` : ""}
      />
    </Main>
  );
}

export default Home;
